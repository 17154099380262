
.collapsible {
  display: flex;
  flex-flow: column;
  width: 100%;

  .header {
    background: #292929;
    padding: 8px;
    cursor: pointer;

    > :before {
      content: '+';
      width: 24px;
    }

    &.opened {
      > :before {
        content: '–';
      }
    }

    .title {
      display: flex;
      font-size: 1.5em;
      align-items: center;
      user-select: none;

      .icon {
        width: 48px;
        height: 48px;
        margin-right: 8px;
      }

      .name {
        width: 250px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .count {
        width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .content {
    margin-left: 16px;

    .inner {
      margin: 8px 0;
    }
  }

  .entries {
    display: flex;
    flex-flow: column;
    margin-left: 12px;

    .entry {
      display: flex;
      align-items: center;
      margin-right: 8px;

      .icon {
        width: 48px;
        height: 48px;
        margin: 4px;
        margin-right: 8px;
      }

      .title {
        width: 300px;
        font-size: 1.2em;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .perks {
        display: flex;

        width: 280px;

        .icon {
          width: 36px;
          height: 36px;
        }
      }
    }
  }
}