
.loader-icon,
.loader-icon:before,
.loader-icon:after {
  width: 150px;
  height: 150px;
  border-radius: 50%;

  box-sizing: border-box;
}

.loader-icon {
  margin: 0 auto;
  position: relative;
  background: transparent;
}

.loader-icon:before,
.loader-icon:after {

  @keyframes rotate-animation {
    100% {
      transform: rotate(360deg);
    }
  }

  position: absolute;
  left: 0;
  top: 0;
  content: "";
  border: 15px solid #efefef;
  opacity: 0.5;
  animation: rotate-animation 3s infinite linear;
}

.loader-icon:before {
  border-left-color: transparent;
}

.loader-icon:after {
  top: 16.5%;
  left: 16.5%;
  width: 100px;
  height: 100px;
  border-right-color: transparent;
  opacity: 0.2;
  animation-direction: reverse;
}

