.root {
  margin: 0;
  padding: 0;
  overflow: hidden;

  width: 100vw;
  height: 100vh;

  display: flex;
  flex-flow: column;
  justify-content: start;
  align-items: center;

  * {
   box-sizing: border-box;
  }

  a { color: white; }

  .container {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    width: 100%;
    height: 100%;

    .settings {
      padding: 8px;
      width: 400px;
      height: 100%;
      margin-right: 24px;
      overflow-y: auto;
      position: relative;

      hr {
        margin-top: 24px;
        margin-bottom: 24px;
      }

      .spacer {
        height: 12px;
      }

      .sort {
        width: 100%;
        background: rgba(255, 255, 255, 0.075);
      }

      .reload {
        position: absolute;
        top: 8px;
        right: 8px;
      }

      .about {
        margin-top: auto;
      }
    }

    .list {
      overflow: auto;
      height: 100%;
      width: 100%;
    }
  }
}

:global(.ui-reorderable-list) {
  padding: 4px;
  min-height: 32px;
  border: 1px solid white;
  background: rgba(255, 255, 255, 0.075);

  &:empty::before {
    content: 'You can drag criteria items here to disable';
    color: #ababab;
  }
}

:global(.ui-reorderable-item) {
  font-size: 1.5em;
  width: 100%;
  cursor: move;
  color: white;
  user-select: none;
}

.loading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  > * {
    margin-top: 8px !important;
  }
}


.error {
  width: 100%;
  margin: 0 auto;
}
